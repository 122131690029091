import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        redirect: '/home'
    },
    {
        path: "/",
        component: () => import("@/views/index.vue"),
        children: [{
            path: "/home",
            component: () => import("@/views/home/index.vue")
        },
            {
                path: '/about',
                component: () => import('../views/about/index.vue')
            },
            {
                path: '/case',
                component: () => import('../views/case/index.vue')
            },
            {
                path: '/talent',
                component: () => import('../views/talent/index.vue')
            },
            {
                path: '/news',
                component: () => import('../views/news/index.vue')
            },
            {
                path: '/news2',
                component: () => import('../views/news2/index.vue')
            },
            {
                path: "/new_detail",
                name: "new_detail",
                component: () => import('../views/news/new_detail.vue')
            },
            {
                path: '/contact',
                component: () => import('../views/contact/index.vue')
            },
            {
                path: "/",
                redirect: "/home"
            },]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

export default router;
